.wrap {
  width: 100%;
  top: 168px !important;
}
.container_md {
  margin-top: 69px;
}
.header_divider {
  margin-top: 96px;
  max-width: 1200px;
  border: 0.5px solid #C0C0CF;
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: calc(100% - 120px) !important;
  }
}
@media (min-width: 1700px) {
  .container_md {
    max-width: 1600px !important;
  }
}
