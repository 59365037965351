.lg-container .lg-outer {
  border-radius: 8px;
}
.lg-container.lg-show.lg-show-in.lg-inline {
  position: relative;
  height: 100%;
  width: 100%;
}
.lg-container.lg-show.lg-show-in.lg-inline .lg-fullscreen {
  display: none;
}
.lg-container.lg-show.lg-show-in.lg-inline .lg-backdrop {
  background: transparent;
}
.lg-container .lg-toolbar {
  opacity: 0;
  transition: opacity 0.5s;
}
.lg-container .lg-content {
  top: 0 !important;
}
.lg-container .lg-pager-outer {
  margin-bottom: 16px;
}
.lg-container .lg-pager-cont {
  margin: 0 2px !important;
}
.lg-container .lg-pager-cont > span {
  box-shadow: none !important;
  width: 8px !important;
  height: 8px !important;
  background: #F6F6F9 !important;
}
.lg-container .lg-pager-cont.lg-pager-active > span {
  background: #00205B !important;
}
.lg-container .lg-next,
.lg-container .lg-prev {
  width: 40px;
  height: 40px;
  background: #F6F6F9;
  opacity: 0.85 !important;
  border-radius: 50%;
  border: none;
  transition: all 0.2s ease;
}
.lg-container .lg-next:hover,
.lg-container .lg-prev:hover {
  opacity: 1 !important;
}
.lg-container .lg-next:before,
.lg-container .lg-prev:after {
  content: '';
  width: 18px;
  height: 11px;
  display: block;
}
.lg-container .lg-prev {
  left: 10px;
}
.lg-container .lg-prev:after {
  background: url("../../icons/arrow.svg") no-repeat;
  transform: rotate(90deg);
  margin-right: 2px;
}
.lg-container .lg-next {
  right: 10px;
}
.lg-container .lg-next:before {
  background: url("../../icons/arrow.svg") no-repeat;
  transform: rotate(270deg);
  margin-left: 2px;
}
.lg-container .lg-pager-thumb-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0;
  border: 5px solid white;
}
.lg-container .lg-pager-thumb-cont img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: initial;
}
.lg-container .lg-img-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.lg-container .lg-img-wrap img {
  width: 100%;
  height: auto;
  max-height: initial;
  pointer-events: none;
}
.lg-container .lg-maximize {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00205B;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  margin: 10px;
}
.lg-container .lg-maximize:after {
  content: '';
  background: url('../../icons/fullscreen.svg') no-repeat;
  display: block;
  width: 24px;
  height: 24px;
}
.lg-container:not(.lg-container.lg-inline) .lg-maximize:after {
  background: url('../../icons/smallscreen.svg') no-repeat 50% 50%;
}
.lg-container:not(.lg-container.lg-inline) .lg-img-wrap img {
  width: auto;
  height: auto;
}
.lg-container:hover .lg-toolbar {
  opacity: 1;
  transition: opacity 0.2s;
}
.fullscreen {
  transform: inherit !important;
}
