.swiper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.swiper * {
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.swiper .swiper-button-next,
.swiper .swiper-button-prev {
  position: absolute;
  pointer-events: none;
  height: 100%;
  width: 100%;
}
.swiper .swiper-button-next:after,
.swiper .swiper-button-prev:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 5%;
  top: 0;
  pointer-events: none;
  z-index: 5;
}
.swiper .swiper-button-prev:not(.swiper .swiper-button-disabled):after {
  left: 0;
}
.swiper .swiper-button-next:not(.swiper .swiper-button-disabled):after {
  right: 0;
}
.swiper .swiper-wrapper {
  display: flex;
  width: fit-content;
}
.swiper .swiper-wrapper .swiper-slide {
  width: auto !important;
}
.mapLayoutGrid .swiper-button-prev:not(.mapLayoutGrid .swiper-button-disabled):after {
  background: linear-gradient(270deg, rgba(238, 240, 245, 0) 25.96%, #F6F6F9 100%);
}
.mapLayoutGrid .swiper-button-next:not(.mapLayoutGrid .swiper-button-disabled):after {
  background: linear-gradient(270deg, #F6F6F9 25.96%, rgba(238, 240, 245, 0) 100%);
}
.sensorLayoutGrid .swiper-button-prev:not(.sensorLayoutGrid .swiper-button-disabled):after {
  background: linear-gradient(270deg, rgba(238, 240, 245, 0) 25.96%, #F5F8FE 100%);
}
.sensorLayoutGrid .swiper-button-next:not(.sensorLayoutGrid .swiper-button-disabled):after {
  background: linear-gradient(270deg, #F5F8FE 25.96%, rgba(238, 240, 245, 0) 100%);
}
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  background: #F3F3F3;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid transparent;
  width: 400px;
  height: 52px;
  transition: all 0.2s ease;
}
input[type="text"]:hover,
input[type="number"]:hover,
input[type="email"]:hover,
input[type="password"]:hover,
input[type="text"]:active,
input[type="number"]:active,
input[type="email"]:active,
input[type="password"]:active,
input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #C0C0CF;
  background: #F3F3F3;
  box-shadow: none;
}
.btn-primary {
  background: #00205B !important;
  border-radius: 10px !important;
  height: 52px;
  color: #FFFFFF !important;
  border: none !important;
}
.btn-primary:hover {
  background-color: #002B7A !important;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  box-shadow: none !important;
}
a {
  color: #32324D !important;
  text-decoration: none !important;
}
.flex {
  display: flex;
}
.flex-imp {
  display: flex !important;
}
.flex-top-center {
  display: flex;
  align-items: center;
}
.flex-top-center-imp {
  display: flex !important;
}
.flex-top-center {
  display: flex;
  align-items: center;
}
.flex-top-center-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-top-center-tablet {
    display: flex !important;
    align-items: center !important;
  }
  .flex-top-center-tablet-imp {
    display: flex !important;
  }
}
.flex-left-center {
  display: flex;
  justify-content: center;
}
.flex-left-center-imp {
  display: flex !important;
}
.flex-left-center {
  display: flex;
  justify-content: center;
}
.flex-left-center-imp {
  display: flex !important;
}
.flex-left-center-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-left-center-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-left-center-tablet-imp {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .flex-left-center-desktop {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-left-center-desktop-imp {
    display: flex !important;
  }
}
@media (max-width: 993px) {
  .flex-left-center-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-left-center-tablet-imp {
    display: flex !important;
  }
  .flex-left-center-tablet-imp {
    display: flex !important;
  }
}
@media (max-width: 993px) and (max-width: 993px) {
  .flex-left-center-tablet-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-left-center-tablet-tablet-imp {
    display: flex !important;
  }
}
@media (max-width: 993px) and (min-width: 992px) {
  .flex-left-center-tablet-desktop {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-left-center-tablet-desktop-imp {
    display: flex !important;
  }
}
.flex-left-center {
  display: flex;
  justify-content: center;
}
.flex-left-center-imp {
  display: flex !important;
}
.flex-left-center-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-left-center-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-left-center-tablet-imp {
    display: flex !important;
  }
}
@media (max-width: 993px) {
  .flex-left-center-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-left-center-tablet-imp {
    display: flex !important;
  }
  .flex-left-center-tablet-imp {
    display: flex !important;
  }
}
@media (max-width: 993px) and (max-width: 993px) {
  .flex-left-center-tablet-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-left-center-tablet-tablet-imp {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .flex-left-center-desktop {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-left-center-desktop-imp {
    display: flex !important;
  }
  .flex-left-center-desktop-imp {
    display: flex !important;
  }
}
@media (min-width: 992px) and (max-width: 993px) {
  .flex-left-center-desktop-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-left-center-desktop-tablet-imp {
    display: flex !important;
  }
}
.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}
.flex-center-imp {
  display: flex !important;
}
.flex-center-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-center-tablet {
    display: flex !important;
    align-items: center !important;
  }
  .flex-center-tablet-imp {
    display: flex !important;
  }
}
.flex-center-imp {
  display: flex !important;
}
.flex-center-imp {
  display: flex !important;
}
.flex-center-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-center-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-center-tablet-imp {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .flex-center-desktop {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-center-desktop-imp {
    display: flex !important;
  }
}
@media (max-width: 993px) {
  .flex-center-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-center-tablet-imp {
    display: flex !important;
  }
  .flex-center-tablet-imp {
    display: flex !important;
  }
}
@media (max-width: 993px) and (max-width: 993px) {
  .flex-center-tablet-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-center-tablet-tablet-imp {
    display: flex !important;
  }
}
@media (max-width: 993px) and (min-width: 992px) {
  .flex-center-tablet-desktop {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-center-tablet-desktop-imp {
    display: flex !important;
  }
}
.flex-center-imp {
  display: flex !important;
}
.flex-center-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-center-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-center-tablet-imp {
    display: flex !important;
  }
}
@media (max-width: 993px) {
  .flex-center-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-center-tablet-imp {
    display: flex !important;
  }
  .flex-center-tablet-imp {
    display: flex !important;
  }
}
@media (max-width: 993px) and (max-width: 993px) {
  .flex-center-tablet-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-center-tablet-tablet-imp {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .flex-center-desktop {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-center-desktop-imp {
    display: flex !important;
  }
  .flex-center-desktop-imp {
    display: flex !important;
  }
}
@media (min-width: 992px) and (max-width: 993px) {
  .flex-center-desktop-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-center-desktop-tablet-imp {
    display: flex !important;
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column-imp {
  display: flex !important;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-column {
    align-items: center;
  }
}
@media (max-width: 993px) {
  .flex-column-tablet {
    display: flex !important;
    flex-direction: column !important;
  }
  .flex-column-tablet-imp {
    display: flex !important;
  }
}
@media (max-width: 560px) {
  .flex-column-mobile {
    display: flex !important;
    flex-direction: column !important;
  }
  .flex-column-mobile-imp {
    display: flex !important;
  }
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row-imp {
  display: flex !important;
}
@media (min-width: 992px) {
  .flex-row-d {
    display: flex;
    align-items: center;
    max-width: 990px;
  }
  .flex-row-d-imp {
    display: flex !important;
  }
  .flex-row-d > * {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-between-imp {
  display: flex !important;
}
@media (min-width: 992px) {
  .flex-between-desktop {
    display: flex;
    justify-content: space-between;
  }
  .flex-between-desktop-imp {
    display: flex !important;
  }
}
.flex-evenly {
  display: flex;
  justify-content: space-evenly;
}
.flex-evenly-imp {
  display: flex !important;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
.flex-around-imp {
  display: flex !important;
}
.display-none {
  display: none;
}
.h-500 {
  height: 500px;
}
.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.px-7 {
  padding-left: 7px !important;
  padding-right: 7px !important;
}
.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.pt-15 {
  padding-top: 15px;
}
.pt-50 {
  padding-top: 50px;
}
.pb-40 {
  padding-bottom: 40px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-120 {
  margin-bottom: 120px;
}
.fw-normal {
  font-weight: normal;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-share {
  cursor: url("../icons/Hover_icon_open.svg"), auto;
}
.pointer-event-none {
  pointer-events: none;
}
.color-red {
  color: #D02B20;
}
.color-main-color-primary {
  color: #00205B;
}
.bg-color-main-color-form-bg {
  background-color: #FFFFFF;
}
.b-shadow {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
.container-box {
  border-radius: 10px;
  width: 100%;
}
.container-box-shadow {
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
.container-header {
  margin: 0 0 -10px;
  font-weight: 500;
  color: #00205B;
}
.listItem {
  border-bottom: 1px solid #EAEAEF;
  min-height: 40px;
}
.listItem > div[role="button"] {
  min-height: inherit;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.listItem span {
  font-size: 16px !important;
  text-transform: capitalize;
}
.opacity-0 {
  opacity: 0 !important;
}
.selector {
  color: #00205B !important;
  background: transparent;
  font-size: 16px !important;
  border: 1px solid #666687;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  transition: all 0.2s ease;
  padding: 0;
  height: auto;
  font-family: 'Ubuntu', sans-serif;
}
.selector > svg {
  color: #00205B;
  margin-right: 10px;
  transition: all 0.2s ease;
  cursor: pointer;
  height: 100%;
  position: absolute;
  right: 0;
}
.selector > svg.select_is_open {
  transform: rotate(180deg);
}
.selector.active {
  color: #FFFFFF !important;
  background: #00205B;
  border-color: #00205B;
}
.selector.active > svg {
  display: none;
  color: #FFFFFF;
}
.selector fieldset {
  border: none;
}
.selector > div {
  padding: 5px 40px 5px 20px !important;
}
.selector > div span.count {
  display: none;
}
.MuiPaper-root {
  margin-top: 13px;
  border-radius: 8px !important;
}
.MuiPaper-root ul {
  padding: 0;
}
.MuiPaper-root ul li {
  min-width: 200px;
  min-height: 40px;
  color: #00205B;
  display: block;
  line-height: 180%;
}
.MuiPaper-root ul li[aria-selected='true'] {
  background: #F6F6F9;
}
.MuiPaper-root ul li:hover {
  background: #DCDCE4 !important;
}
.MuiPaper-root ul li .count {
  color: #666687;
}
.MuiPaper-root ul li .selector_remove_btn {
  display: none;
}
.selector_remove_btn {
  position: absolute;
  top: 0;
  right: 8px;
  padding: 0 10px;
  width: 34px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: all 0.2s ease;
}
.selector_remove_btn > * {
  width: 18px !important;
  height: 18px !important;
}
.selector_remove_btn:hover {
  opacity: 0.9;
  color: #FFFFFF;
}
body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  font-family: 'Ubuntu', sans-serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
h1 {
  font-size: 96px;
  color: #00205B !important;
  font-weight: 400;
}
h2 {
  color: #00205B !important;
}
h3 {
  font-size: 18px !important;
  line-height: 26px !important;
  color: #00205B !important;
}
h5 {
  font-size: 34px !important;
  line-height: 49px !important;
  font-weight: 400;
  color: #00205B !important;
}
span {
  font-size: 16px;
}
p {
  font-size: 18px;
}
small {
  font-size: 10px;
}
button:focus,
button:active {
  box-shadow: none !important;
}
.tooltip .tooltip-inner {
  padding: 5px;
  background: #2D78C6;
  color: #FFFFFF;
  max-width: 210px;
}
.tooltip[data-popper-placement='top'] .tooltip-arrow:before {
  border-top-color: #2D78C6;
}
.sensor-card .time-box {
  transition: all 0.5s ease;
  opacity: 0;
}
.sensor-card .time-box.time-box-for-hover {
  opacity: 1;
}
#bg_heizkessel_on {
  position: absolute;
  transition: opacity 5s;
}
@keyframes fadeOut_anim {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeTo_anim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeTo_9000_1 {
  animation: fadeTo_anim 9s;
  opacity: 1;
}
.fadeTo_9000_0 {
  animation: fadeOut_anim 9s;
  opacity: 0;
}
.fadeTo_5000_1 {
  animation: fadeTo_anim 5s;
  opacity: 1;
}
.fadeTo_5000_0 {
  animation: fadeOut_anim 5s;
  opacity: 0;
}
.tabs {
  justify-content: center;
  flex-wrap: nowrap;
  border: none;
  transition: all 0.5s ease;
}
.tabs > li button {
  color: #00205B !important;
  border: none;
  border-width: 0 !important;
  border-bottom: 2px solid #00205B !important;
  opacity: 0.25;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.5s ease;
}
.tabs > li button[aria-selected='true'] {
  opacity: 1;
  font-weight: 500;
}
.detail_card > li button {
  font-size: 20px;
  font-weight: 400;
}
.tab {
  transition: all 0.5s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.disable_tab {
  display: none;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
  pointer-events: none;
}
.fade-enter > * {
  pointer-events: auto;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-out;
  pointer-events: none;
}
.fade-enter.fade-enter-active > * {
  pointer-events: auto;
}
.fade-exit {
  opacity: 1;
  pointer-events: none;
}
.fade-exit > * {
  pointer-events: auto;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 1s ease-out;
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow: inherit;
  pointer-events: none;
}
.fade-exit.fade-exit-active > * {
  pointer-events: auto;
}
.row {
  --bs-gutter-x: 0;
}
.slick-slider {
  position: relative;
  background: white;
}
.slick-slider > * {
  outline: none;
}
.slick-slider .slick-track {
  display: flex;
  flex-direction: row;
}
.slick-slider .slick-track img {
  border-radius: 10px;
}
.slick-slider .slick-arrow {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translate(0, -50%);
  width: 40px;
  height: 40px;
  background: #F6F6F9;
  opacity: 0.85;
  border-radius: 26.6667px;
  border: none;
  transition: all 0.2s ease;
}
.slick-slider .slick-arrow svg {
  color: #00205B;
}
.slick-slider .slick-arrow.slick-prev {
  left: 8px;
  padding-right: 8px;
}
.slick-slider .slick-arrow.slick-prev svg {
  transform: rotate(180deg);
}
.slick-slider .slick-arrow.slick-next {
  right: 8px;
  padding-left: 8px;
}
.slick-slider .slick-arrow.slick-disabled {
  opacity: 0;
  pointer-events: none;
}
.slick-slider .slick-arrow:hover {
  opacity: 1;
}
.slick-slider .slick-dots {
  position: absolute;
  display: flex !important;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  margin: 0;
  padding: 0;
}
.slick-slider .slick-dots li {
  display: block;
}
.slick-slider .slick-dots li button {
  visibility: hidden;
  width: 8px;
  height: 8px;
  padding: 0;
  margin-right: 4px;
}
.slick-slider .slick-dots li button:after {
  content: '';
  display: block;
  width: inherit;
  height: inherit;
  background: #F6F6F9;
  position: absolute;
  top: 0;
  visibility: visible;
  border-radius: 50%;
}
.slick-slider .slick-dots li.slick-active button:after {
  background: #00205B;
}
.lg-container {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
}
.details_card.nav-tabs .nav-link {
  font-size: 18px;
}
.MuiCalendarPicker-root .MuiTypography-caption {
  margin: 0;
}
.MuiCalendarPicker-root .css-1n2mv2k,
.MuiCalendarPicker-root .css-mvmu1r {
  margin-left: 40px;
}
.MuiCalendarPicker-root .MuiPickersDay-root {
  line-height: 100%;
}
.MuiCalendarPicker-root .css-x1wgng-MuiYearPicker-root,
.MuiCalendarPicker-root .css-1wvgxus-MuiCalendarPicker-viewTransitionContainer {
  min-height: 304px;
  align-items: baseline;
}
.MuiCalendarPicker-root .css-qtzvnr-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  border: none;
}
.MuiCalendarPicker-root .Mui-selected {
  background-color: white !important;
  color: #000000DD !important;
}
.MuiCalendarPicker-root .Mui-selected:hover {
  background-color: #0000000A !important;
}
.pnlm-ui .pnlm-load-box {
  display: none !important;
}
.pnlm-container {
  background: #F6F8FC !important;
  transition: all 0.5s ease;
}
.pnlm-controls {
  display: none !important;
}
.pnlm-hotspot-base:not(.custom-hotspot) {
  background: none;
}
.pnlm-hotspot-base:not(.custom-hotspot) > .hotspot {
  transform: translate(-62%, -29%);
}
.isNotFullScreen .hotspot {
  zoom: 0.6;
  transform: translate(-22%, -16%);
}
