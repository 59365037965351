.wrap .briefInfo {
  position: relative;
  margin-bottom: 30px;
  color: #00205B;
  transition: all 0.2s ease;
  z-index: 2;
  pointer-events: none;
  opacity: 0;
}
.wrap .briefInfo.hidden {
  opacity: 0;
}
.wrap .briefInfo span,
.wrap .briefInfo a {
  width: auto;
  margin-right: 10px;
}
.wrap .briefInfo span:not(:first-child),
.wrap .briefInfo a:not(:first-child) {
  margin-left: 10px;
}
.wrap .briefInfo span.bold,
.wrap .briefInfo a.bold {
  text-decoration: underline !important;
  font-weight: bold;
  color: #00205B !important;
}
.wrap .container {
  transition: all 0.2s ease;
  position: relative;
  background: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-height: 220px;
  margin-bottom: 110px;
  padding: 16px;
  color: #00205B;
  z-index: 2;
}
.wrap .container .image_wrap {
  width: 275px;
  max-height: 190px;
  overflow: hidden;
  padding-left: 0;
}
.wrap .container .image_wrap .lazy_load_image {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 251px;
  height: 188px;
  overflow: hidden;
  border-radius: 8px;
}
.wrap .container .image_wrap .lazy_load_image img {
  margin: auto 0;
  height: 100%;
  width: auto;
}
.wrap .title {
  font-weight: 500;
}
@media (min-width: 992px) {
  .small_3D {
    margin-top: calc(85vw - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 1200px) {
  .small_3D {
    margin-top: calc(80vw - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 1440px) {
  .small_3D {
    margin-top: calc(75vw - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 1600px) {
  .small_3D {
    margin-top: calc(1150px - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 1900px) {
  .small_3D {
    margin-top: calc(1150px - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 2300px) {
  .small_3D {
    margin-top: calc(1150px - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 3000px) {
  .small_3D {
    margin-top: calc(1200px - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 3300px) {
  .small_3D {
    margin-top: calc(1250px - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 3500px) {
  .small_3D {
    margin-top: calc(1300px - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 3800px) {
  .small_3D {
    margin-top: calc(1350px - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 4200px) {
  .small_3D {
    margin-top: calc(1400px - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 992px) {
  .big_3D {
    margin-top: -80px;
  }
}
@media (min-width: 1050px) {
  .big_3D {
    margin-top: -50px;
  }
}
@media (min-width: 1150px) {
  .big_3D {
    margin-top: 10px;
  }
}
@media (min-width: 1250px) {
  .big_3D {
    margin-top: 60px;
  }
}
@media (min-width: 1350px) {
  .big_3D {
    margin-top: 110px;
  }
}
@media (min-width: 1440px) {
  .big_3D {
    margin-top: 160px;
  }
}
@media (min-width: 1550px) {
  .big_3D {
    margin-top: 220px;
  }
}
@media (min-width: 1650px) {
  .big_3D {
    margin-top: 275px;
  }
}
@media (min-width: 1750px) {
  .big_3D {
    margin-top: 325px;
  }
}
@media (min-width: 1850px) {
  .big_3D {
    margin-top: 380px;
  }
}
@media (min-width: 1950px) {
  .big_3D {
    margin-top: 435px;
  }
}
@media (min-width: 2050px) {
  .big_3D {
    margin-top: 490px;
  }
}
@media (min-width: 2150px) {
  .big_3D {
    margin-top: 545px;
  }
}
@media (min-width: 2250px) {
  .big_3D {
    margin-top: 600px;
  }
}
@media (min-width: 2350px) {
  .big_3D {
    margin-top: 655px;
  }
}
@media (min-width: 2450px) {
  .big_3D {
    margin-top: 710px;
  }
}
@media (min-width: 2550px) {
  .big_3D {
    margin-top: 765px;
  }
}
@media (min-width: 2650px) {
  .big_3D {
    margin-top: 820px;
  }
}
@media (min-width: 2750px) {
  .big_3D {
    margin-top: 875px;
  }
}
@media (min-width: 2850px) {
  .big_3D {
    margin-top: 930px;
  }
}
@media (min-width: 2950px) {
  .big_3D {
    margin-top: 985px;
  }
}
@media (min-width: 3050px) {
  .big_3D {
    margin-top: 1040px;
  }
}
@media (min-width: 3150px) {
  .big_3D {
    margin-top: 1095px;
  }
}
@media (min-width: 3250px) {
  .big_3D {
    margin-top: 1150px;
  }
}
@media (min-width: 3350px) {
  .big_3D {
    margin-top: 1205px;
  }
}
@media (min-width: 3450px) {
  .big_3D {
    margin-top: 1260px;
  }
}
@media (min-width: 3550px) {
  .big_3D {
    margin-top: 1315px;
  }
}
@media (min-width: 3650px) {
  .big_3D {
    margin-top: 1370px;
  }
}
@media (min-width: 3750px) {
  .big_3D {
    margin-top: 1425px;
  }
}
@media (min-width: 3850px) {
  .big_3D {
    margin-top: 1480px;
  }
}
@media (min-width: 3950px) {
  .big_3D {
    margin-top: 1535px;
  }
}
