@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.card_wrapper {
  min-width: 100%;
  height: 100%;
  height: -webkit-fill-available;
}
.usage_map_wrap {
  margin-top: -168px !important;
  max-width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.usage_map_wrap .sensor_style {
  transition: all 0.5s ease;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.usage_map_wrap .sensor_style:before {
  content: '';
  background: url("../../../images/performance/sensors/sensor_hover.png") no-repeat;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background-size: contain !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.5s ease;
  pointer-events: none;
  cursor: pointer;
}
.usage_map_wrap .sensor_style.status_bad:before {
  background: url("../../../images/performance/sensors/sensor_alarm_noPipes.png") no-repeat;
  width: 90%;
  height: 90%;
  opacity: 1;
  animation: show 0.5s;
}
.usage_map_wrap .sensor_style:hover:before {
  opacity: 1;
}
.usage_map_wrap .sensor_style.status_bad:hover:before {
  background: url("../../../images/performance/sensors/sensor_hover.png") no-repeat;
}
.usage_map_wrap .valve_style {
  transition: all 0.5s ease;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.usage_map_wrap .valve_style:before {
  content: '';
  background: url("../../../images/performance/sensors/smart_valve_hover.png") no-repeat;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background-size: contain !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.5s ease;
  pointer-events: none;
  cursor: pointer;
}
.usage_map_wrap .valve_style.status_bad:before {
  background: url("../../../images/performance/sensors/smart_valve_alarm.png") no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  opacity: 1;
  animation: show 0.5s;
}
.usage_map_wrap .valve_style:hover:before {
  opacity: 1;
}
.usage_map_wrap .valve_style.status_bad:hover:before {
  background: url("../../../images/performance/sensors/smart_valve_hover.png") no-repeat;
}
.usage_map_wrap .flush_valve_style {
  transition: all 0.5s ease;
  z-index: 1;
  cursor: pointer;
}
.usage_map_wrap .flush_valve_style:before {
  content: '';
  background: url("../../../images/performance/sensors/flush_valve_hover.png") no-repeat;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background-size: contain !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.5s ease;
  pointer-events: none;
  cursor: pointer;
}
.usage_map_wrap .flush_valve_style.status_bad:before {
  background: url("../../../images/performance/sensors/flush_valve_alarm.png") no-repeat;
  width: 100%;
  height: 100%;
  opacity: 1;
  animation: show 0.5s;
}
.usage_map_wrap .flush_valve_style:hover:before {
  opacity: 1;
}
.usage_map_wrap .flush_valve_style.status_bad:hover:before {
  background: url("../../../images/performance/sensors/flush_valve_hover.png") no-repeat;
}
.usage_map_wrap .backwash_filter_style {
  transition: all 0.5s ease;
  z-index: 1;
  cursor: pointer;
}
.usage_map_wrap .backwash_filter_style:before {
  content: '';
  background: url("../../../images/performance/sensors/smart_backwashfilter_hover.png") no-repeat;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background-size: contain !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.5s ease;
  pointer-events: none;
  cursor: pointer;
}
.usage_map_wrap .backwash_filter_style.status_bad:before {
  background: url("../../../images/performance/sensors/smart_backwashfilter_alarm.png") no-repeat;
  width: 100%;
  height: 100%;
  opacity: 1;
  animation: show 0.5s;
}
.usage_map_wrap .backwash_filter_style:hover:before {
  opacity: 1;
}
.usage_map_wrap .backwash_filter_style.status_bad:hover:before {
  background: url("../../../images/performance/sensors/smart_backwashfilter_hover.png") no-repeat;
}
.usage_map_wrap .large_sensor_style.status_bad:before {
  width: 100%;
  height: 100%;
}
.usage_map_wrap.hidden_tooltips .sensor_style {
  opacity: 0;
  pointer-events: none;
}
.usage_map_wrap .renderElement_wrap {
  transition: all 0.2s ease;
}
.usage_map_wrap .animation {
  position: absolute;
  top: 3px;
  left: -7px;
  pointer-events: none;
}
.usage_map_wrap .tess {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.usage_map_wrap .hidden_tooltip {
  opacity: 0;
  pointer-events: none;
}
.usage_map_wrap .img_layer {
  transition: opacity 0.5s ease;
  pointer-events: none;
}
.usage_map_wrap .img_layer.hidden {
  opacity: 0;
}
.usage_map_wrap .usage_map,
.usage_map_wrap .img_layer {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
}
@media (min-width: 992px) {
  .usage_map_wrap .small_3D_images {
    margin: -5% -30% 0 -30%;
    width: calc(100vw + 60%) !important;
    max-width: none;
  }
}
@media (min-width: 1200px) {
  .usage_map_wrap .small_3D_images {
    margin: 0% -30% 0 -35%;
    width: calc(100vw + 50%) !important;
    max-width: none;
  }
}
@media (min-width: 1440px) {
  .usage_map_wrap .small_3D_images {
    margin: -1% -25% 0 -25%;
    width: calc(100vw + 40%) !important;
    max-width: none;
  }
}
@media (min-width: 1700px) {
  .usage_map_wrap .small_3D_images {
    margin: -1% -25% 0 -25%;
    width: calc(100vw + 40%) !important;
    max-width: none;
  }
}
@media (min-width: 1700px) {
  .usage_map_wrap .small_3D_images {
    margin: -9% -20% 0 -20%;
    width: calc(100vw + 40%) !important;
    max-width: none;
  }
}
@media (min-width: 1900px) {
  .usage_map_wrap .small_3D_images {
    margin: -9% -12% 0 -12%;
    width: calc(100vw + 24%) !important;
    max-width: none;
  }
}
@media (min-width: 2300px) {
  .usage_map_wrap .small_3D_images {
    margin: -8% -3% 0 -3%;
    width: calc(100vw + 6%) !important;
    max-width: none;
  }
}
@media (min-width: 2700px) {
  .usage_map_wrap .small_3D_images {
    margin: -9% 0 0 0;
    width: calc(100vw) !important;
    max-width: none;
  }
}
@media (min-width: 3300px) {
  .usage_map_wrap .small_3D_images {
    margin: -10% 0 0 0;
    width: calc(100vw) !important;
    max-width: none;
  }
}
@media (min-width: 3500px) {
  .usage_map_wrap .small_3D_images {
    margin: -11% 0 0 0;
    width: calc(100vw) !important;
    max-width: none;
  }
}
@media (min-width: 3800px) {
  .usage_map_wrap .small_3D_images {
    margin: -12% 0 0 0;
    width: calc(100vw) !important;
    max-width: none;
  }
}
@media (min-width: 4200px) {
  .usage_map_wrap .small_3D_images {
    margin: -13% 0 0 0;
    width: calc(100vw) !important;
    max-width: none;
  }
}
.usage_map_wrap * {
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
@media (min-width: 3000px) {
  .small_3D_images_wrap {
    height: 1250px;
    max-height: 1250px;
  }
}
@media (min-width: 3300px) {
  .small_3D_images_wrap {
    height: 1300px;
    max-height: 1300px;
  }
}
@media (min-width: 3500px) {
  .small_3D_images_wrap {
    height: 1350px;
    max-height: 1350px;
  }
}
@media (min-width: 3800px) {
  .small_3D_images_wrap {
    height: 1400px;
    max-height: 1400px;
  }
}
@media (min-width: 4200px) {
  .small_3D_images_wrap {
    height: 1450px;
    max-height: 1450px;
  }
}
@media (min-width: 2100px) {
  .big_3D_images_wrap {
    height: auto;
    max-height: 1500px;
  }
}
@media (min-width: 2600px) {
  .big_3D_images_wrap {
    max-height: 1800px;
  }
}
@media (min-width: 3200px) {
  .big_3D_images_wrap {
    max-height: 2100px;
  }
}
@media (min-width: 3700px) {
  .big_3D_images_wrap {
    max-height: 2400px;
  }
}
.red_line,
.red_line_2 {
  position: absolute;
  background: #D02B20;
  z-index: 1;
}
.red_line {
  height: 110px;
  width: 2px;
  left: -5px;
  top: -44%;
}
.red_line_2 {
  width: 85px;
  height: 2px;
  left: -5px;
  top: -44%;
}
.render_lines {
  transition: all 0.2s ease;
}
.hidden_lines {
  opacity: 0;
  pointer-events: none;
}
.performance_mouse_hover {
  width: 100%;
  height: 100%;
  position: absolute;
}
.card_wrap {
  width: fit-content;
  height: fit-content;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s ease;
  z-index: 99999999999999999999;
}
.card_wrap.show {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.prototype_sensor {
  z-index: 3;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s ease;
  transform: translate(-50%, -50%);
  left: -350%;
  top: -200%;
}
.prototype_sensor > div {
  margin: 0;
}
.prototype_sensor.show {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.clickable_area {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
}
.elements {
  z-index: 1;
  position: absolute;
}
.elements.cursor {
  cursor: pointer;
}
.arrows_container .arrow_button {
  cursor: pointer;
  width: 24px;
  height: 20px;
  border: none;
  background-color: rgba(28, 28, 28, 0);
}
.arrows_container .arrow_button .arrow_bottom {
  transform: rotate(180deg);
}
.arrows_container .arrow_button .arrow_icon {
  width: 16px;
  height: 16px;
}
.arrows_container .disabled_button {
  opacity: 0.2;
}
.arrows_container .enabled_button {
  opacity: 1;
}
.valves_layer {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
  -webkit-transform: translateY(0) !important;
  transform: translateY(0) !important;
  -webkit-transition: all 0.5s ease-in-out !important;
  transition: all 0.5s ease-in-out !important;
}
.animation_layer_group1 {
  visibility: hidden !important;
  opacity: 0 !important;
  filter: alpha(opacity=0) !important;
  -webkit-transform: translateY(-50px) !important;
  transform: translateY(-50px) !important;
}
.animation_layer_group2 {
  visibility: hidden !important;
  opacity: 0 !important;
  filter: alpha(opacity=0) !important;
  -webkit-transform: translateY(50px) !important;
  transform: translateY(50px) !important;
}
.transition_switching_group {
  opacity: 1 !important;
  transition: opacity 0.5s linear !important;
}
.hidden_valves_layer {
  opacity: 0 !important;
}
.showArrows {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s, visibility 1s linear 0.3s;
}
.hiddenArrows {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 1s linear 0.3s;
}
.circulationTabsContainer {
  width: 261px;
}
.circulationTabsContainer > li {
  width: 100%;
}
.circulationTabsContainer > li button {
  width: 100%;
  background-color: transparent !important;
  padding: 0 !important;
  font-size: 20px !important;
}
