/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.button {
  min-width: 30px !important;
  height: 30px;
  width: 30px !important;
  background: #F6F6F9 !important;
  color: #00205B !important;
  position: absolute !important;
  right: 33.5px;
}
.button:hover,
.button:active,
.button:focus {
  box-shadow: none !important;
}
.popover_container {
  background-color: #F6F6F9;
  height: 270px;
  border-radius: 0 !important;
}
.popover_container .radio_group_container {
  padding: 0 8px 0 3px;
}
.one_line_container {
  width: 250px;
}
.two_line_container {
  width: 320px;
}
.control_container {
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  margin-right: 53px;
  background-color: #0000FF;
  height: auto;
}
.control_container .title {
  color: white;
}
.control_container .title span {
  margin: 0;
}
.control_container .title .selected_value {
  font-weight: 500;
}
.control_container .title .selected_building {
  margin-left: 5px;
}
.control_container .arrow {
  transition: all 0.5s ease;
  width: 10px;
  height: 7px;
  cursor: pointer;
}
.control_container .active_arrow {
  transform: rotate(180deg);
  cursor: pointer;
}
