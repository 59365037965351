.cropper-container {
  background-color: #F6F8FC;
  width: 60vw !important;
  height: 70vh !important;
}
.cropper-view-box {
  border-radius: 50%;
}
.btn-primary {
  width: 150px;
}
