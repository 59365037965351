.icon {
  display: flex;
  max-width: 326px;
}
.icon:hover:after {
  margin-left: 2px;
  content: url(../../../icons/edit.svg);
}
.fieldContainer {
  height: 68px;
  margin-bottom: 43px;
}
.fieldContainer.withoutMargin {
  margin-bottom: 0;
}
.fieldContainer .fieldTitle {
  color: #00205B;
  font-weight: 500;
  text-transform: uppercase;
}
.fieldContainer .input {
  height: 25px;
  width: auto;
  max-width: 300px;
  color: #4A4A6A;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance:none) {
    .fieldContainer .input {
      padding-left: 1px;
    }
  }
}
.fieldContainer .input[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: sticky;
  color: #8E8EA9;
  background-color: transparent;
}
.fieldContainer .input:hover,
.fieldContainer .input:focus,
.fieldContainer .input:active {
  text-underline-offset: 1.5px;
  text-decoration-line: underline;
  background: none;
  border: none;
  outline: none;
}
.fieldContainer .input:empty:hover {
  text-decoration-line: none !important;
}
.fieldContainer .input:empty:focus {
  text-underline-offset: 1.5px;
  text-decoration-line: underline !important;
}
.fieldContainer .input:before,
.fieldContainer .input:after {
  content: none;
  border-bottom: none;
}
