.modal_sensor {
  position: absolute;
  top: 25%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s ease;
  z-index: 3;
}
.modal_sensor > div {
  margin: 0;
}
.modal_sensor.show {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  z-index: 3;
}
.container {
  margin: 0 -1px 10px 0;
  background: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 29px 12px 26px;
  text-align: center;
  color: #00205B;
  width: 233px;
  height: 366px;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 3;
}
.container.isModal {
  margin: 0;
  z-index: 3;
}
.container .name_wrap {
  min-height: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
.container .name_wrap .name {
  width: 100%;
  font-weight: 500;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
